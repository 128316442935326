<template>
  <div :class="{ 'input--invalid': error }" class="input">
    <a-input-password
        :placeholder="placeholder"
        :required="required"
        :value="value ? value : undefined"
        @input="$emit('input', $event.target.value)"
    />
    <div v-if="error" class="input__error">{{ error[0] }}</div>
  </div>
</template>

<script>
import AInputPassword from 'ant-design-vue/lib/input/Password'

export default {
  name: "VInputPassword",
  components: {
    AInputPassword
  },
  props: {
    placeholder: String,
    required: Boolean,
    error: Array,
    value: [Number, String]
  },
  created() {
    function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

    var _icon = require('ant-design-vue/lib/icon');

    var _icon2 = _interopRequireDefault(_icon);

    var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

    var _defineProperty3 = _interopRequireDefault(_defineProperty2);

    var ActionMap = {
      click: 'click',
      hover: 'mouseover'
    };

    AInputPassword.methods.getIcon = function getIcon() {
      var _on;

      var h = this.$createElement;
      var _$props = this.$props,
          prefixCls = _$props.prefixCls,
          action = _$props.action;

      var iconTrigger = ActionMap[action] || '';
      var iconProps = {
        props: {
          type: this.visible ? 'eye-invisible' : 'eye'
        },
        on: (_on = {}, (0, _defineProperty3['default'])(_on, iconTrigger, this.onVisibleChange), (0, _defineProperty3['default'])(_on, 'mousedown', function mousedown(e) {
          e.preventDefault();
        }), (0, _defineProperty3['default'])(_on, 'mouseup', function mouseup(e) {
          e.preventDefault();
        }), _on),
        'class': prefixCls + '-icon',
        key: 'passwordIcon'
      };
      return h(_icon2['default'], iconProps);
    }
  }
}
</script>

<style lang="scss" >
.input {
  margin-bottom: 26px;

  input {
    width: 100%;
    height: auto;
    padding: 15px 24px!important;
    border: 1px solid #D5D9E1!important;
    border-radius: 4px;
    font-size: 15px;
    line-height: 18px;
    color: #1D293F;
    transition: all 200ms ease;

    &::placeholder {
      color: #9ea3a7;
    }

    &:focus {
      outline: none;
      border-color: #00ADFF;
      box-shadow: unset;
    }

    &:hover {
      border-color: #00ADFF;
    }
  }

  &__error {
    display: none;
    opacity: 0;
    font-size: 12px;
    line-height: 17px;
    color: #F2323F;
    transition: all 200ms ease;
  }

  &--invalid {
    input {
      margin-bottom: 8px;
      background-color: #FFEAEA;
      border-color: #F2323F!important;
    }

    .input__error {
      display: block;
      opacity: 1;
    }
  }
}
</style>
