<template>
  <div class="login">
    <div class="login__wrapper">
      <div class="login__form-wrapper">

        <router-link to="/">
          <img alt="logo" class="login__logo" src="../assets/logo.svg">
        </router-link>

        <form class="login__form" @submit.prevent="auth">
          <div class="login__form-input-wrapper">
            <v-input
              v-model="login"
              autofocus
              placeholder="Логин"
              :error="errors.login"
              required
            />
          </div>

          <div class="login__form-input-wrapper">
            <v-input-password
              v-model="password"
              placeholder="Пароль"
              :error="errors.password"
              required
            />
          </div>

          <div class="login__form-input-wrapper login__remember-password-wrapper">
            <v-checkbox v-model="remember" text="Запомнить пароль"/>
          </div>

          <div class="login__form-submit-wrapper">
            <v-button type="submit" size="large">Войти</v-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import VInput from '../components/ui/VInput'
import VButton from '../components/ui/VButton'
import VCheckbox from '../components/ui/VCheckbox'
import VInputPassword from "@/components/ui/VInputPassword";

export default {
  name: "Login",
  data() {
    return {
      login: null,
      password: null,
      remember: false,
      errors: {},
    }
  },
  components: {
    VInput,
    VInputPassword,
    VButton,
    VCheckbox
  },
  methods: {
    async auth() {
      this.errors = {}
      await this.$store
        .dispatch('login', {
          login: this.login,
          password: this.password,
          remember: this.remember,
        })
        .then(() => this.$router.push({name: 'Home'}))
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        })
    }
  },
}
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  padding: 24px;
  min-height: 100vh;

  &__wrapper {
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
  }

  &__logo {
    width: 300px;
    margin: 0 auto 24px auto;
  }

  &__login__form-wrapper {
    max-width: 360px;
    padding: 20px;
  }

  &__form-input-wrapper {
    max-width: 300px;
    margin-bottom: 24px;
  }

  &__remember-password-wrapper {
    text-align: center;
  }

  &__form-submit-wrapper {
    display: flex;
    justify-content: center;
  }
}

</style>
