<template>
  <a-checkbox @change="$emit('input', $event.target.checked)">
    {{ text }}
  </a-checkbox>
</template>

<script>
import ACheckbox from 'ant-design-vue/lib/checkbox';

export default {
  name: "VCheckbox",
  components: {
    ACheckbox
  },
  props: {
    text: String,
  },
}
</script>

<style lang="scss">
</style>
